.print_noPrint__1k3CN, .print_projectRow__2N6UI, .print_container__2J2ew {}

@media print
{
    .print_noPrint__1k3CN, .print_noPrint__1k3CN *
    {
        display: none !important;
    }

    .print_container__2J2ew {
        width: 100%;
    }

    .print_noBreakInside__22unm {
        page-break-inside: avoid;
    }

}


.LinkedInLink_link__2Lfoz {
    white-space: nowrap;
}

.ResumeSummary_noPrint__3uUyZ {}

@media print
{
    .ResumeSummary_noPrint__3uUyZ, .ResumeSummary_noPrint__3uUyZ *
    {
        display: none !important;
    }
}
.DateDisplay_dateDisplay__3GvMx {
    white-space: nowrap;
}

.EmployerProject_noBreakInside__qd7l6 {}

@media print {
    .EmployerProject_noBreakInside__qd7l6 {
        page-break-inside: avoid;
    }
}
