.noPrint, .projectRow, .container {}

@media print
{
    .noPrint, .noPrint *
    {
        display: none !important;
    }

    .container {
        width: 100%;
    }

    .noBreakInside {
        page-break-inside: avoid;
    }

}

